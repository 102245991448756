
<template>
  <CContainer>
    <CRow>
    <CCol sm="6" lg="3">
      <CWidgetDropdown color="primary" :header="$formatterNumeroTipoCreditos(creditos_sms,1)" text="Creditos Sms">
        <template #footer>
          <CChartLineSimple
            pointed
            class="mt-3 mx-3"
            style="height:70px"
            :data-points="[65, 59, 84, 84, 51, 55, 40]"
            point-hover-background-color="primary"
            label="Members"
            labels="months"
          />
        </template>
      </CWidgetDropdown>
    </CCol>
    <CCol sm="6" lg="3" v-if="validaUsuarioPadre == 0">
      <CWidgetDropdown
        color="success"
        :header="$formatterNumeroTipoCreditos(creditos_billetera,1)"
        text="Saldo Billetera"
      >
        <template #footer>
          <CChartBarSimple
            class="mt-3 mx-3"
            style="height:70px"
            background-color="rgb(250, 152, 152)"
            label="Members"
            labels="months"
          />
        </template>
      </CWidgetDropdown>
    </CCol>
  </CRow>
    <CRow>
    <CCol col="12">
      <CCard>
        <CCardHeader>
          <h3>Historial de Recargas</h3>
        </CCardHeader>
         <template v-if="validaUsuarioPadre == 0">
              <CRow>
                <CCol col="3" class="ml-3 mt-1">
                    <CSelect
                        label="Historial"
                        placeholder="Historial"
                        :options="[{value:'billetera',label:'Historial Billetera'},{value:'sms',label:'Historial SMS'},{value:'mail',label:'Historial Mail'}]"
                        required
                        was-validated
                        @change="filtroItemsHistorial"
                        :value.sync="historial_select"
                        >
                    </CSelect>  
                </CCol>
              </CRow>   
         </template>
        <CCardBody>
          <CDataTable
            hover
            striped
            :items="items"
            :fields="fields"
            :items-per-page="10"
            :pagination="{ doubleArrows: false, align: 'center'}"
            column-filter
            @page-change="pageChange"
          >
          <template #creditos="{item, index}">
              <td>
             {{$formatterNumeroTipoCreditos(item.creditos)}}
             </td>
           </template>
          </CDataTable>
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
  </CContainer>
</template>

<script>
import { CChartLineSimple,CChartBarSimple } from '../charts/index.js'
export default {
  name: 'RecargasUsuarios',
  components: {
    CChartLineSimple,
    CChartBarSimple
  },
  data () {
    return {
      mostrarNotificacion: 0,
      mostrarNotificacionAlerta: 0,
      mostrarNotificacionError:0,
      mensaje_warning:'Debe Completar Los Datos Vacio',
      historial_select:'sms',
      items: [],
      items_filter: [],
      fields: [
        { key: 'fecha_creacion',label:'Fecha',_classes: 'font-weight-bold'  },
        { key: 'creditos', label: 'Creditos'},
        { key: 'descripcion',label:'Descripcion'},
        
      ],
      activePage: 1,
      creditos_prueba_modal:false,
      creditos_modal:false,
      creditos_billetera_modal:false,
      usuario:{
        name: ''
      },
      asignarCreditos:{
        saldo:0.00,
        saldo_prueba:50.00,
        description:'',
      },
      creditos_sms:0.00,
      creditos_billetera:0.00,
      creditos_bille:0.00,

    }
  },
  computed: {
    validaUsuarioPadre(){
      try {
        return this.$store.state.auth.user.padre;
      } catch (error) {
        return 1
      }    
    }
  },
  watch: {
    validaUsuarioPadre: {
      handler(newValue) {
        this.filtroItemsHistorial();
      },
      immediate: true
    }
  },
  methods: {
    goBack() {
      this.usersOpened ? this.$router.go(-1) : this.$router.push({path: '/roles'})
    },
    obtenerUsuarioHistorialPorId () {
        const $self = this;
        this.$store.dispatch('auth/loading_http','visible');
        this.$http.get('/sms/historial/recargas').then(response => {
            this.items_filter = response.data.historial;
            this.usuario = response.data.usuarios;
            this.creditos_sms = `${response.data.creditos.sms}`;
            this.creditos_billetera = `${response.data.creditos.billetera}`;
            $self.filtroItemsHistorial();
            this.asignarCreditos.saldo = 0.00;
            this.asignarCreditos.description = '';
        }).catch((e)=>{
          this.mostrarNotificacionError ++;evt.target.disabled = false;
        }).finally(() =>this.$store.dispatch('auth/loading_http','hidden'));
    },
    validator (val) {
      return val ? val.length >= 4 : false
    },
    isNumber: function(evt) {
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57))) {
        evt.preventDefault();;
      } else {
        return true;
      }
    },
    filtroItemsHistorial(){
        
        this.items = this.validaUsuarioPadre != 0 ? this.items_filter : this.items_filter.filter((p)=>p.tipo_historial == this.historial_select);
        this.items.map((item,id) => {  
          if (id%2 == 0) {
            item['_classes'] = 'table-now'; 
          }
          return item;
        })

        if (this.historial_select == 'billetera') {
          this.fields =  [
            { key: 'fecha_creacion',label:'Fecha',_classes: 'font-weight-bold'  },
            { key: 'creditos', label: 'Saldo'},
            { key: 'descripcion',label:'Descripcion'},
              ]
        }else if (this.validaUsuarioPadre != 0){
          this.fields =  [
              { key: 'fecha_creacion',label:'Fecha',_classes: 'font-weight-bold'  },
              { key: 'creditos', label: 'Creditos'},
              { key: 'descripcion',label:'Descripcion'},
              { key: 'tipo_historial',label:'Tipo Historial'},
              ]
        }else{
          this.fields =  [
            { key: 'fecha_creacion',label:'Fecha',_classes: 'font-weight-bold'  },
            { key: 'creditos', label: 'Creditos'},
            { key: 'descripcion',label:'Descripcion'},
              ]
        }
    },
    pageChange (val) {
      this.$router.push({ query: { page: val }})
    },
    formatNumber(n) {
      // format number 1000000 to 1,234,567
      return n.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
    formatCurrency(event, blur) {
      // appends $ to value, validates decimal side
      // and puts cursor back in right position.

      // get input value
      var input_val = this.asignarCreditos.saldo;

      // don't validate empty input
      if (input_val === "") { return; }

      // original length
      var original_len = input_val.length;

      // initial caret position 
      var caret_pos = event.target.selectionStart;
        
      // check for decimal
      if (input_val.indexOf(".") >= 0) {

        // get position of first decimal
        // this prevents multiple decimals from
        // being entered
        var decimal_pos = input_val.indexOf(".");

        // split number by decimal point
        var left_side = input_val.substring(0, decimal_pos);
        var right_side = input_val.substring(decimal_pos);

        // add commas to left side of number
        left_side = this.formatNumber(left_side);

        // validate right side
        right_side = this.formatNumber(right_side);
        
        // On blur make sure 2 numbers after decimal
        if (blur === "blur") {
          right_side += "00";
        }
        
        // Limit decimal to only 2 digits
        right_side = right_side.substring(0, 2);

        // join number by .
        input_val = "$" + left_side + "." + right_side;

      } else {
        // no decimal entered
        // add commas to number
        // remove all non-digits
        input_val = this.formatNumber(input_val);
        input_val = "$" + input_val;
        
        // final formatting
        if (blur === "blur") {
          input_val += ".00";
        }
      }

      // send updated string to input
      this.asignarCreditos.saldo  = input_val
  },
  resetModal(){
  },
  },
  mounted () {
      setTimeout(() => {
        this.obtenerUsuarioHistorialPorId();
      }, 100);
  },
}
</script>
